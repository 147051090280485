import React from "react"
import SEO from "../components/seo"

import "../styles/garage.css"

const IndexPage = () => (
  <>
    <SEO title="A Garagem" />
    <div id="logo">
      <svg
        width="256"
        height="256"
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.615 117L213.595 227.879V161H512V376.288H0L149.615 117ZM149.615 186.143L59.8461 341.716H239.384L149.615 186.143ZM248.147 341.716H477.448V195.572H248.147V341.716Z"
          fill="black"
        />
      </svg>
    </div>
  </>
)

export default IndexPage
